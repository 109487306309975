import React, { PureComponent } from "react"
import LottieControl from "./lottie/scrollCTAclass"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Link, graphql } from "gatsby"
import {
  isMobileOnly,
  isBrowser,
  isMobile,
  isTablet,
  browserName,
  withOrientationChange
} from "react-device-detect"

export default class ParallaxScroll extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      scrollHeight: 0,
      scrollOfset: 0,
      scrollPercentage: 0,
      didScroll: false,
      scrollVal: 10
    }

    this.handleScrollAnimation = this.handleScrollAnimation.bind(this)
  }

  handleScrollAnimation(scrolltop) {
    var that = this
    that.setState({
      didScroll: true,
      scrollVal: 0
    })
    // Create cross browser requestAnimationFrame method:
    window.requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (f) {
        setTimeout(f, 1000 / 60)
      }
    var cutoutText = document.getElementById("cutoutText")
    var underText = document.getElementById("underText")
    var homeCarousel = document.getElementById("homeCar")
    var sizzleTheaterButton = document.getElementsByClassName(
      "sizzle-button-under"
    )[0]
    var comFooter = document.getElementById("main-homepage")
    let scrollHeight = this.state.scrollHeight
    let scrollOfset = this.state.scrollOfset
    let scrollPercentage = this.state.scrollPercentage
    function parallaxScroll(selectedElement) {
      if (!scrolltop) {
        scrolltop = window.pageYOffset // get number of pixels document has scrolled vertically
      }
      if (scrolltop > 1) {
        if (!isMobileOnly) {
          //window.scrollTo(0, window.pageYOffset + that.state.scrollVal)
          setTimeout(function () {
            underText.style.opacity = "1"
            sizzleTheaterButton.style.opacity = "1"

            homeCarousel.style.display = "block"
          }, 200)
        } else {
          underText.style.opacity = "1"
          sizzleTheaterButton.style.opacity = "1"
        }

        setTimeout(function () {
          homeCarousel.style.display = "block"
          comFooter.style.display = "block"
        }, 1500)

        selectedElement.style.top = -100 + "vh"
      }
      // else {
      //   selectedElement.style.top = 0 + "vh"
      // }
    }

    function animateOnScroll() {
      cutoutText = document.getElementById("cutoutText")
      requestAnimationFrame(() => {
        if (cutoutText) {
          parallaxScroll(cutoutText)
        }
      }) // call parallaxScroll() on next available screen paint
      scrollHeight =
        Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.body.clientHeight,
          document.documentElement.clientHeight
        ) - window.innerHeight

      scrollOfset = Math.round(window.pageYOffset)
      scrollPercentage = (scrollOfset / scrollHeight) * 100
      requestAnimationFrame(() => {
        if (cutoutText) {
          that.setState({
            scrollPercentage: scrollPercentage,
            scrollHeight: scrollHeight,
            scrollOfset: scrollOfset,
            didScroll: true
          })
        }
      })

      return scrollPercentage
    }

    animateOnScroll()
  }

  componentDidMount() {
    document.documentElement.classList.add("beforeWheel")
    window.addEventListener("DOMMouseScroll", preventDefault, false)
    if (!isMobileOnly) {
      window.ontouchmove = preventDefault // mobile
    }
    document.addEventListener("wheel", preventDefault, { passive: false }) // Disable scrolling in Chrome
    window.onwheel = preventDefault // modern standard
    window.onmousewheel = document.onmousewheel = preventDefault // older browsers, IE

    document.onkeydown = preventDefaultForScrollKeys // keys

    var keys = { 37: 1, 38: 1, 39: 1, 40: 1 }
    var that = this

    function preventDefault(e) {
      e = e || window.event
      if (e.preventDefault) e.preventDefault()
      e.returnValue = false
    }

    function preventDefaultForScrollKeys(e) {
      if (keys[e.keyCode]) {
        preventDefault(e)
        return false
      }
    }

    function scrollOverride() {
      document.documentElement.classList.remove("beforeWheel")
      that.handleScrollAnimation(2)
      window.removeEventListener("wheel", wheelEvent)
      window.removeEventListener("touchstart", wheelEvent)
      window.removeEventListener("DOMMouseScroll", wheelEvent)
      window.removeEventListener("onwheel", wheelEvent)
      window.removeEventListener("onmousewheel", wheelEvent)
      window.removeEventListener("ontouchmove", wheelEvent)
      window.removeEventListener("keydown", wheelEvent)
      document.removeEventListener("onkeydown", wheelEvent)
      clearTimeout(forceScroll)
      setTimeout(() => {
        window.removeEventListener("DOMMouseScroll", preventDefault, false)
        document.removeEventListener("wheel", preventDefault, {
          passive: false
        }) // Enable scrolling in Chrome
        window.onwheel = null
        window.onmousewheel = document.onmousewheel = null
        window.ontouchmove = null
        document.onkeydown = null
      }, 1500)
    }

    const wheelEvent = e => {
      const delta = Math.sign(e.deltaY)
      if (delta != -1) {
        scrollOverride()
      }
    }

    var forceScroll

    if (isMobileOnly) {
      forceScroll = scrollOverride()
    } else {
      forceScroll = setTimeout(() => {
        scrollOverride()
      }, 7000)
    }

    window.addEventListener("wheel", wheelEvent)
    window.addEventListener("touchstart", wheelEvent)
    window.addEventListener("DOMMouseScroll", wheelEvent)
    window.addEventListener("onwheel", wheelEvent)
    window.addEventListener("onmousewheel", wheelEvent)
    window.addEventListener("ontouchmove", wheelEvent)
    document.addEventListener("keydown", wheelEvent)
    window.addEventListener("onkeydown", wheelEvent)

    // if (this.state.didScroll == false) {
    //   setTimeout(function() {
    //     //window.scrollTo(0, window.pageYOffset + that.state.scrollVal)
    //     //that.handleScrollAnimation()
    //   }, 7000)
    // }
  }

  componentWillUnmount() {}

  render() {
    const CutoutTextContent = props => {
      let dyStart = -2
      if (this.props.svgHeadings.length === 4) {
        dyStart = -3.5
      }

      if (this.props.language === "es") {
        return (
          <svg
            className="overlay-svg"
            width="100vw"
            height="100vh"
            viewBox="0 0 100 50"
            preserveAspectRatio="xMidYMid slice">
            <defs>
              <mask id="mask" x="0" y="0" width="100vw" height="100vh">
                <rect
                  x="0"
                  y="0"
                  width="100vw"
                  height="100vh"
                  fill="#fff"></rect>
                <rect
                  x="0"
                  y="0"
                  width="100vw"
                  height="100vh"
                  fill="#fff"></rect>
                {this.props.svgHeadings.map((heading, i) => (
                  <text
                    key={heading}
                    className={`svg-text-${i + 1} scroll-svg-text`}
                    textAnchor="middle"
                    x="50"
                    y="50%"
                    dy={`${dyStart + i * 1.25}em`}>
                    {heading}
                  </text>
                ))}
              </mask>
              <div className="column has-text-centered"></div>
            </defs>
            <rect
              x="0"
              y="0"
              width="100vw"
              height="100vh"
              mask="url(#mask)"
              fillOpacity="1"
              fill="#fff"></rect>
            {this.props.svgHeadings.map((heading, i) => (
              <text
                key={heading}
                className={`svg-text-${i + 1}`}
                textAnchor="middle"
                fill="red"
                fillOpacity="0"
                x="50"
                y="50%"
                dy={`${dyStart + i * 1.25}em`}>
                {heading}
              </text>
            ))}
          </svg>
        )
      } else {
        return (
          <svg
            className="overlay-svg"
            width="100%"
            height="100%"
            viewBox="0 0 100 50"
            preserveAspectRatio="xMidYMid slice">
            <defs>
              <mask id="mask" x="0" y="0" width="100%" height="100%">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff"></rect>
                <rect x="0" y="0" width="100%" height="100%" fill="#fff"></rect>
                {this.props.svgHeadings.map((heading, i) => (
                  <text
                    key={heading}
                    className={`svg-text-${i + 1}`}
                    textAnchor="middle"
                    x="50"
                    y="50%"
                    dy={`${dyStart + i * 1.25}em`}>
                    {heading}
                  </text>
                ))}
              </mask>
              <div className="column has-text-centered"></div>
            </defs>
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              mask="url(#mask)"
              fillOpacity="1"
              fill="#fff"></rect>
            {this.props.svgHeadings.map((heading, i) => (
              <text
                key={heading}
                className={`svg-text-${i + 1}`}
                textAnchor="middle"
                fill="red"
                fillOpacity="0"
                x="50"
                y="50%"
                dy={`${dyStart + i * 1.25}em`}>
                {heading}
              </text>
            ))}
          </svg>
        )
      }
    }

    //   <div className="asdf" style={{fontSize: "2rem", marginTop: "10rem"}}>
    //   {isMobileOnly.toString()}
    //   <br/>isMobileOnly
    //   {isMobile.toString()}
    //   <br/>isMobile
    //   {isBrowser.toString()}
    //   <br/>isBrowser
    //   {isTablet.toString()}
    //   <br/>isTablet
    //   {browserName.toString()}
    //   <br/>browserName
    // </div>

    var cutoutStyle = {
      display: isMobileOnly ? "none" : ""
      // display: "none"
    }

    return (
      <>
        <div
          id="cutoutText"
          className="video-overlay text-box"
          style={cutoutStyle}>
          <CutoutTextContent />
          <TheaterVideo
            videoUrl={`https://www.youtube.com/watch?v=${this.props.sizzleYoutube}`}
            controls={true}
            playing={true}
            onlyButton={true}
            compId={3}
            language={this.props.language}
            slideButton
          />
          <LottieControl
            isMobileOnly={isMobileOnly}
            scrollHeight={this.state.scrollHeight}
          />
        </div>
      </>
    )
  }
}
